module.exports = {
  // App Info
  appstore_link: "https://kintimer.app.link/bN2pFH7Nb4", // Enter App Store URL.
  //playstore_link: "https://imedadel.me", // Enter Google Play Store URL.
  google_analytics_ID: "UA-75096825-12", // Enter Google Analytics ID or ""
  //presskit_download_link: "https://imedadel.me", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://kintimer.app", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "KinTimer: 금욕 타이머",
  app_price: "무료",
  app_description: "계속 유지하면서 습관화하는 금욕・금연 카운터",
  app_keywords: ["금욕", "타이머", "카운터", "습관"],
  download_label: "다운로드",
  // Personal Info
  your_name: "",
  your_link: "",
  your_city: "",
  email_address: "helloheydays@gmail.com",
  linkedin_username: null,
  facebook_username: null,
  //instagram_username: "ed_adel",
  twitter_username: "kintimer_appp",
  //github_username: "ImedAdel",
  youtube_username: null,

  // Features List
  features: [
    {
      title: "사용이 편리하고 직관적",
      description:
        "사용하기 아주 편해요! 지금까지의 기록을 한눈에 알 수 있어요.",
      fontawesome_icon_name: "sliders-h"
    },
    {
      title: "계급 기능",
      description:
        "계급표로 순위를 확인합시다. 금욕한 날의 수에 따라 순위가 올라갑니다!",
      fontawesome_icon_name: "medal"
    },
    {
      title: "로그 기능",
      description:
        "지금까지의 금욕 이력이 자동으로 기록됩니다. 어느 정도 계속했는지 돌아봅시다!",
      fontawesome_icon_name: "history"
    }
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666"
};
